
.admin-table-create-cell {

    a {
        float: right;
        font-size: 42px;
        font-weight: 600;
        text-decoration: none;
        color: #2a00f8;
        background: transparent;
    }
}