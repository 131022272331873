@font-face {
  font-family: "Copperplate";
  font-style: normal;
  font-weight: normal;
  src: local('Copperplate'), url('./Fonts/Copperplate.ttf') format('truetype');
}

@font-face {
  font-family: "Cooper-Black";
  font-style: normal;
  font-weight: normal;
  src: local('Cooper-Black'), url('./Fonts/Cooper-Black.ttf') format('truetype');
}

@font-face {
  font-family: "Stencil";
  font-style: normal;
  font-weight: normal;
  src: local('Stencil'), url('./Fonts/Stencil.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: Arial !important;
  font-style: normal;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

header {
  height: 65px;
  max-width: 498px;
  width: 100%;
  background-color: #38b23a;
  position: fixed;
  top: 0;
  z-index: 9;
  border: 1px solid transparent;

  .header-container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;

    .logo {
      height: 50px;
      width: auto;
      object-fit: contain;
      margin: auto;
      padding-top: 4px;
    }

    a {
      color: black !important;
      background-color: transparent !important;
      text-decoration: none;
      font-size: 14px;
    }

    .cart-quantity-circle {
      display: inline-block;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      border: 1px solid black;
      font-size: 12px;
      text-align: center;
      line-height: 17px;
      margin-left: 5px;
      background-color: #ca4b42;
    }
  }
}

.main-app-container {
  min-height: 100vh;
  min-width: 100vw;
  background-color: black;
}

.sub-app-container {
  max-width: 500px;
  margin: 0 auto;
  padding-top: 65px;
  background-color: #38b23a;
}

/* Define css class for global loading message to cover 
   screen during axios operations */
@keyframes spinner {
  to {transform: rotate(360deg);}
}

.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 45%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid rgb(136, 131, 131);
  border-top-color: #fff;
  animation: spinner .6s linear infinite;
  z-index: 1001; 
}

.mar-vert-12 {
  margin: 12px 0px;
}

.mar-vert-20 {
  margin: 20px 0px; 
}

.mar-vert-24 {
  margin: 24px 0px;
}

.mar-vert-36 {
  margin: 36px 0px;
}

.mar-vert-60 {
  margin: 60px 0px;
}


/* CSS Code Icons */
.info::before { 
  content: "\1f6c8"; 
}