
.cart-checkout-table-cell {

    .cc-section {
        display: block;
        margin: 10px 0px;

        .cc-section-label {
            display: block;
            width: 100%;
            font-size: 17px;
            font-weight: 600;
        }

        .cc-section-sum-label {
            display: block;
            width: 90%;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            font-size: 14px;

            span {
                float: right;
            }
        }

        .cc-section-sum-label:nth-child(2) {
            padding-top: 10px;
        }

        button {
            display: block;
            height: 40px;
            width: 55%;
            padding: 0;
            margin: 0 auto;
            border-radius: 6px;
            border: none;
            outline: none;
            background-color: #38b23a;
            color: white;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
        }

        .cc-section-input {
            margin: 10px 10px;
            width: 90%;

            input {
                display: block;
                height: 25px;
                width: 100%;
                font-size: 14px;
                font-weight: 600;
                color: black;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #aaada7;
                outline: none;
                border-radius: 0;
            }

            input::placeholder {
                color: #aaada7;
            }
        }

        .cc-section-textarea {
            margin: 10px 10px;
            width: 90%;

            textarea {
                display: block;
                height: 38px;
                width: 100%;
                font-size: 14px;
                font-weight: 600;
                color: black;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #aaada7;
                outline: none;
                resize: none;
                border-radius: 0;
            }

            textarea::placeholder {
                color: #aaada7;
            }

            .cc-section-input-error {
                border-bottom: 2px solid #9c1926;
            }
        }

        .cc-section-textarea-inline {
            display: flex;
            justify-content: space-between;

            textarea {
                width: 40%;
            }

            .cc-section-input-error {
                border-bottom: 2px solid #9c1926;
            }
        }

        .cc-section-input-error {

            input {
                border-bottom: 2px solid #9c1926;
            }
            
        }
    }

    .cc-section:nth-child(2) {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .cc-section-error {
        font-size: 15px;
        text-align: center;
        color: #9c1926;

        span {
            display: block;
            margin: 4px 0px;
        }
    }

    .cc-section-file-input {
        display: block;
        width: 90%;
        margin: 25px 10px;
        text-align: center;
        background-color: #d9d9d8;
        border-radius: 6px;
        position: relative;
        

        label {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            font-size: 15px;
        }
        
        * {
            cursor: pointer;
        }

        input {
            display: none;
        }

        img {
            height: 25px;
            width: 25px;
            position: absolute;
            right: 20px;
            top: 8px;
            cursor: default;
        }

        .cc-section-input-error {
            border-bottom: 2px solid #9c1926;
        }
    }
}

.checkout-table-cell {

    .cc-section {

        input {
            text-align: center;
        }
        
        button {
            width: 50%;
            margin-bottom: 40px;
        }
    }
}



