
.admin-outlet-transaction-page-container {
    min-height: 100vh;
    background-color: #f2f3f5;
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
}

.aot-section {
    display: block;
    width: 100%;
    margin-bottom: 40px;

    label {
        font-size: 16px;
        font-weight: 600;
        text-decoration: underline;
    }

    .aot-balance-type-container {
        display: flex;
        justify-content: center;
        margin: 15px 0px;

        input {
            margin-left: 20px;
        }
        span {
            margin-right: 20px;
        }
    }

    .aot-balance-input-container {

        input {
            display: inline-block;
            height: 22px;
            width: 95%;
            font-size: 16px;
            color: black;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #aaada7;
            outline: none;
            border-radius: 0;
            margin-top: 10px;
            margin-left: 5px;
        }

        button {
            display: block;
            height: 40px;
            width: 100%;
            padding: 0;
            margin: 0 auto;
            border-radius: 6px;
            border: none;
            outline: none;
            background-color: #38b23a;
            color: white;
            font-size: 14px;
            font-weight: 600;
            margin-top: 20px;
            cursor: pointer;
        }
    }
}

.aot-table {
    height: 100%;
    width: 100%;

    td {
        border-bottom: 1px solid #ccc;
        padding: 15px 0px;

        label {
            font-size: 14px;
            text-decoration: none;
        }

        span {
            font-weight: 500;
        }

        .aot-table-inline {
            display: inline-flex;
            justify-content: space-between;
            width: 100%;
        }

        .aot-table-block {
            
            label {
                display: block;
                width: 100%;
            }
        }
    }
}