
.dispensaries-detail-table-head-cell {

    a {
        color: #2152b2 !important;
        background-color: transparent;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
    }

    .ddt-head-label {
        font-size: 21px;
        font-family: "Cooper-Black";
        float: right;
    }

    img {
        height: 200px;
        width: 100%;
        object-fit: contain;
    }

    .ddt-location {
        
        label {
            display: block;
            width: 100%;
            font-weight: 600;
            text-align: center;
            margin-bottom: 10px;
        }

        span {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 16px;
            text-decoration: underline;
        }
    }
}