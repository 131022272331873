
.cart-checkout-table-head-cell {

    a {
        color: #2152b2 !important;
        background-color: transparent;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
    }

    label {
        font-size: 21px;
        font-family: "Cooper-Black";
        float: right;
    }

    img {
        height: 200px;
        width: 100%;
        object-fit: contain;
    }
}