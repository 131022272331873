
.admin-outlets-inventory-table {
    height: 100%;
    width: 100%;
    padding: 30px;

    .info {
        display: inline-block;
        font-size: 18px;
        color: #2a00f7;
        cursor: pointer;
    }

    .admin-outlets-inventory-table-cell {
        width: 100%;
        padding: 10px 0px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;

        div {
            display: block;
            font-size: 16px;

            label {
                display: block;
                width: 100%;
                font-weight: 600;
            }

            span {
                display: block;
                width: 100%;
                font-size: 14px;
            }
        }
    }

    .aoi-tc-undisplayed {
        color: #bd3e3e;

        label {
            font-weight: unset !important;
        }
    }

    .admin-outlets-inventory-edit-table-cell {
        width: 100%;
        padding: 10px 0px;

        .aoi-images-container {
            display: inline-flex;
            height: auto;
            width: 100%;

            .aoi-image {
                height: 60px;
                width: 60px;
                border: 1px solid #000;
                margin: 0px 12px;
                position: relative;

                .aoi-image-remove {
                    display: block;
                    height: 20px;
                    width: 20px;
                    border-radius: 50%;
                    background: #c3b8b8;
                    margin: 0px;
                    font-size: 12px;
                    font-weight: 600;
                    position: absolute;
                    top: -5px;
                    right: -7px;
                    z-index: 1;
                    text-align: center;
                    line-height: 20px;
                    color: #bd3e3e;
                    cursor: pointer;
                }

                .awsimg-container {
                    margin: 0px;
                }

                img {
                    height: 60px;
                    width: 60px;
                    object-fit: contain;
                    background-color: #000;
                }

                span {
                    height: 100%;
                    width: 100%;
                    text-align: center;
                    font-size: 24px;
                    margin: 0px;
                    line-height: 60px;
                    cursor: pointer;
                }

                input {
                    display: none;
                }
            }
        }

        .aoi-space-between-section-2 {
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            margin: 0px;

            div {
                width: 40%;

                label {
                    width: 100%;
                }
            }
        }

        .aoi-space-between-section-3 {
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            margin: 0px;

            div {
                width: 30%;

                label {
                    width: 100%;
                }
            }
        }

        div {
            display: block;
            font-size: 16px;
            margin: 20px 0px;

            label {
                width: 100%;
                font-weight: 600;
            }

            input, select {
                display: block;
                height: 22px;
                width: 100%;
                font-size: 16px;
                color: black;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #aaada7;
                outline: none;
                border-radius: 0;
                margin-top: 10px;
            }

            .users-select {
                height: 120px;
                width: 50%;
                background: #d7d7d7;
                padding: 12px;
                border-radius: 4px;

                option:checked {
                    color: white;
                    background-color: #1e90ff;
                }
            }

            input[type="file"] {
                padding: 10px 0px;
            }

            button {
                display: block;
                height: 40px;
                width: 100%;
                padding: 0;
                margin: 0 auto;
                border-radius: 6px;
                border: none;
                outline: none;
                background-color: #38b23a;
                color: white;
                font-size: 14px;
                font-weight: 600;
                margin-top: 60px;
                cursor: pointer;
            }

            span {
                display: block;
                width: 100%;
                text-align: left;
                margin: 10px 20px;
            }
        }

        .aoi-weight-container {

            span {
                display: inline-flex;
                justify-content: center;
                width: 100%;
                margin: 10px 0px;

                button {
                    background-color: transparent;
                    color: red;
                    width: 25px;
                    height: 25px;
                    font-size: 20px;
                    margin: 0;
                    position: relative;
                    top: -3px;
                    right: 10px;
                }
            }
        }

        .aoi-add-weight-container {
            div {
                margin: 10px 0px;
            }

            .aoi-add-weight {
                width: auto;
                background-color: transparent;
                color: #2a00f8;
                font-size: 16px;
                margin: 0;
                margin-left: auto;
                margin-right: 0;
            }
        }
        
    }
}