
.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    position:fixed;
    background: white;
    width: 95%;
    max-width: 400px;
    height: auto;
    border-radius: 6px;
    top:35%;
    left:50%;
    transform: translate(-50%,-50%)
}

.modal-head {
    display: block;
    width: 100%;
    height: 50px;
    background: lightgray;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid #000;

    label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 20px;
        font-weight: 600;
    }
}

.modal-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;

    button {
        height: 20px;
        width: auto;
        background: transparent;
        outline: none;
        border: none;
        margin: 0px 8px;
        cursor: pointer;
        font-size: 14px;
    }

    .confirm-btn {
        color: #2a00f8;
        font-weight: 600;
    }

    .cancel-btn {
        color: #bd3e3e;
        font-weight: 600;
    }

}

.aotc-select-container {
    display: block;
    width: 100%;

    select {
        height: 40px;
        width: 100%;
        outline: none;
        border: none;
        border-bottom: 1px solid #aaa;
        font-size: 16px;
        text-align: center;
        border-radius: 0;
        color: black;

        option {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            border-bottom: 1px solid gray;
        }
    }
    
}

.aotc-schedule-container {
    display: block;
    width: 100%;

    input {
        height: 40px;
        width: 100%;
        padding: 0;
        outline: none;
        border: none;
        border-bottom: 1px solid #aaa;
        text-align: center;
        font-size: 18px;
        -webkit-appearance: none;
    }
}

.aotc-order-details-container {
    display: block;
    word-wrap: break-word;
    max-height: 300px;
    padding: 10px;
    white-space: pre;
    overflow-y: auto;
    border-bottom: 1px solid #aaa;
    margin-bottom: 0px;

    .aotc-od-section {
        width: 100%;

        a {
            color: #2a00f8 !important;
            text-decoration: unset;
        }

        label {
            display: block;
            font-size: 16px;
            font-weight: 600;
            margin: 20px 0px 10px 0px;
            text-decoration: underline;
        }

        span {
            display: block;
            font-size: 16px;
            padding-left: 18px;
            margin: 4px 0px;
            white-space: normal;
        }

        img {
            width: 100%;
            max-height: 250px;
            object-fit: contain;
            cursor: pointer;
        }
    }
}

.aotc-order-details-cancel-container {
    display: block;
    width: 100%;

    select {
        height: 40px;
        width: 100%;
        outline: none;
        border: none;
        border-bottom: 1px solid #aaa;
        font-size: 16px;
        text-align: center;

        option {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 35px;
            border-bottom: 1px solid gray;
        }
    }

    textarea {
        display: block;
        resize: none;
        width: 98.6%;
        height: 80px;
        font-size: 16px;
        margin-top: 0px;
        border: 1px solid gray;
        outline: none;
    }
}

.aotc-signature-container {
    height: 200px;
    width: 100%;

    canvas {
        height: 200px;
        width: 100%;
    }
}