
.admin-landing-page-container {
    min-height: 100vh;
    background-color: #38b23a;
    padding-top: 35px;
}

.alp-logo-container {
    display: flex;
    justify-content: center;

    img {
        height: auto;
        width: 70%;
        object-fit: contain;
    }
}

.alp-fields-container {
    display: block;
    height: auto;
    margin: 0 auto;
    width: 80%;

    input {
        display: block;
        height: 45px;
        width: 100%;
        border: none;
        outline: none;
        border-radius: 7px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
        background-color: #a6aaa8;
        color: white;
        padding: 0;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    }

    input::placeholder {
        color: white;
    }

    button {
        display: block;
        height: 45px;
        width: 100%;
        outline: none;
        border: unset;
        border-radius: 7px;
        font-size: 16px;
        background-color: #3ab54a;
        color: white;
        margin-top: 18px;
        padding: 2px;
        text-shadow: black 1px 0px 1px;
        cursor: pointer;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    }
}

.alp-footer-container {

    a {
        display: block;
        height: auto;
        margin: 0 auto;
        margin-top: 50px;
        color: black;
        background: transparent !important;
        border: none;
        outline: none;
        font-size: 14px;
        text-decoration: none !important;
        text-align: center;
    }
}
