
.display-block {
    display: block;
}

.display-none {
    display: none;
}

.aws-image-viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;
}

.aiv-main {
    position:fixed;
    background: white;
    width: 95%;
    max-width: 400px;
    height: auto;
    border-radius: 6px;
    top:45%;
    left:50%;
    transform: translate(-50%,-50%)
}

.aiv-image {

    img {
        height: 50vh !important;
        width: 100% !important;
        object-fit: contain !important;
        background: black !important;
    }
}

.aiv-head {
    display: block;
    width: 100%;
    height: 50px;
    background: lightgray;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid #000;

    label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 20px;
        font-weight: 600;
    }
}

.aiv-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;

    button {
        height: 20px;
        width: auto;
        background: transparent;
        outline: none;
        border: none;
        margin: 0px 8px;
        cursor: pointer;
        font-size: 14px;
    }

    .confirm-btn {
        color: #2a00f8;
        font-weight: 600;
    }

    .cancel-btn {
        color: #bd3e3e;
        font-weight: 600;
    }

}