

.admin-menu-page-container {
    min-height: 100vh;
    background-color: #38b23a;
    padding-top: 70px;
}

.amp-option-container {
    display: block;
    margin: 0 auto;
    margin-top: 50px;
    width: 80%;

    :last-child {
        margin-top: 50px;
        background: #000;
        cursor: pointer;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: white;
        text-decoration: none;
        background: #9fa39c;
        border-radius: 8px;
        margin: 15px 0px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    }
}
