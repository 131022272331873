
.cart-order-details-table-cell {
    
    td {
        padding: 10px 0px;
        padding-right: 20px;
        padding-left: 3px;
    }

    hr {
        border-top: 2px solid #797a7b;
    }

    .cod-info-section {
        display: flex;
        justify-content: space-between;

        label {
            display: block;
            font-size: 14px;
        }

        img {
            height: 50px;
            width: auto;
        }
    }

    .dp-images-container {
        display: block;
        width: auto;
        min-height: 50px;

        .awsimg-container {
            display: inline-block;
            margin-left: 6px;
            cursor: pointer;

            img {
                height: 50px;
                width: 50px;
                object-fit: contain;
                background-color: black;
            }
        }
    }

    .cod-actions-section {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        button {
            background-color: transparent;
            color: #2152b2;
            border: none;
            outline: none;
            font-size: 14px;
            font-weight: 600;
            padding: 0;
            cursor: pointer;
        }
    }
}