
.admin-orders-table-cell {

    td:first-child {
        padding-top: 0px;
    }

    td {
        padding-top: 15px;
    }

    .aotc-section {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 10px 0px;

        a {
            color: #2a00f8 !important;
            text-decoration: unset;
        }

        label {
            font-size: 16px;
            font-weight: 600;

            span {
                font-weight: 500;
            }
        }
    }

    .aotc-assigns {
        
        label {
            color: #2a00f8;
            cursor: pointer;
        }

        span {
            display: inline-block;
            min-width: 54px;
        }
    }

    .aotc-foot {
        display: flex;
        justify-content: space-between;
        width: 95%;
        border-bottom: 2px solid #bbb;
        margin: 0 auto;
        margin-top: 50px;
        padding-bottom: 3px;

        label {
            font-size: 16px;

            span {
                font-size: 14px;
                font-weight: 600;
                color: #2a00f8;
                cursor: pointer;
            }
        }

        .aotc-foot-status {
            font-size: 14px;
            font-weight: 600;
            color: #2a00f8;
            cursor: pointer;
        }
    }
}