
.dispensaries-detail-table-cell {
    display: flex;
    height: 110px;
    width: 130px;
    border: 2px solid black;
    border-radius: 6px;
    margin-top: 25px;
    margin-bottom: 12px;
    margin-left: 12px;
    margin-right: auto;
    cursor: pointer;
    text-align: center;

    td {
        padding: 0px;
    }

    img {
        height: 110px;
        width: 130px;
        object-fit: cover;
        border-radius: 3px;
    }

    label {
        display: block;
        max-width: 130px;
        font-size: 16px;
    }
}

.dispensaries-detail-table-cell:nth-child(2n+1) { 
    margin-right: 12px;
    margin-left: auto;
}