
.admin-outlet-credentials-page-container {
    min-height: 100vh;
    background-color: #f2f3f5;
    padding-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
}

.aoc-section {
    display: block;
    width: 100%;
    margin-bottom: 40px;

    label {
        font-size: 16px;
        font-weight: 600;
        text-decoration: underline;
    }

    .aoc-section-sub {
        margin-top: 20px;
        
        label {
            display: block;
            width: 100%;
            font-size: 14px;
            text-align: center;
            text-decoration: none;
        }
    }

    .aoc-inputs-container {
        margin-top: 20px;

        div {
            display: inline-flex;
            justify-content: space-evenly;
            width: 89%;
            margin: 10px 20px;
        }

        label {
            display: inline-block;
            width: 50%;
            text-decoration: none;
        }

        input {
            display: inline-block;
            height: 22px;
            width: 100%;
            font-size: 16px;
            color: black;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #aaada7;
            outline: none;
            border-radius: 0;
            margin-left: 5px;
        }

        button {
            display: block;
            height: 40px;
            width: 100%;
            padding: 0;
            margin: 0 auto;
            border-radius: 6px;
            border: none;
            outline: none;
            background-color: #38b23a;
            color: white;
            font-size: 14px;
            font-weight: 600;
            margin-top: 20px;
            cursor: pointer;
        }
    }


}