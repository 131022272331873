.admin-controls-table {
    height: 100%;
    width: 100%;
    padding: 30px;

    .admin-controls-edit-table-cell {
        width: 100%;
        padding: 10px 0px;

        div {
            display: block;
            font-size: 16px;
            margin: 20px 0px;

            label {
                width: 100%;
                font-weight: 600;
            }

            input, select {
                display: block;
                height: 22px;
                width: 100%;
                font-size: 16px;
                color: black;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #aaada7;
                outline: none;
                border-radius: 0;
                margin-top: 10px;
            }

            input[type="file"] {
                padding: 10px 0px;
            }

            select {
                display: block;
                width: 30%;
            }

            button {
                display: block;
                height: 40px;
                width: 100%;
                padding: 0;
                margin: 0 auto;
                border-radius: 6px;
                border: none;
                outline: none;
                background-color: #38b23a;
                color: white;
                font-size: 14px;
                font-weight: 600;
                margin-top: 60px;
                cursor: pointer;
            }
        }
    }
}