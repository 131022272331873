
.admin-orders-page-container {
    min-height: 100vh;
    background-color: #f2f3f5;
    padding-top: 70px;
    padding-left: 18px;
    padding-right: 18px;

    table {
        height: 100%;
        width: 100%;

        thead {

            td {
                height: auto;
                width: 100%;

                .aop-head {
                    height: auto;
                    width: 100%;
                    position: relative;

                    label {
                        display: block;
                        width: 100%;
                        text-align: center;
                        font-size: 28px;
                        font-weight: 600;
                    }

                    button {
                        position: absolute;
                        top: 0px;
                        left: 0;
                        background: transparent;
                        font-size: 26px;
                        font-weight: 600;
                        padding: 0;
                        border: none;
                        outline: none;
                        color: #2a00f8;
                        cursor: pointer;
                    }
                }

                .aop-controls {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 80px;

                    button {
                        font-size: 16px;
                        font-weight: 600;
                        background: transparent;
                        border: none;
                        outline: none;
                        color: #9fa39c;
                        cursor: pointer;
                        padding: 0;
                    }

                    .aop-control-selected {
                        color: black;
                    }
                }

                .aop-search {
                    display: block;
                    width: 97%;
                    margin-top: 20px;
                    margin-bottom: 10px;

                    input {
                        width: 100%;
                        height: 35px;
                        border-radius: 4px;
                        outline: none;
                        border: 1px solid gray;
                        padding: 0px 6px; 
                    }
                    
                }

                .aop-map {
                    display: block;
                    width: 100%;

                    button {
                        display: block;
                        width: 100%;
                        margin: 15px auto;
                        outline: none;
                        border: none;
                        font-size: 16px;
                        font-weight: 600;
                        background: none;
                        color: #2a00f8;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}