
.dispensaries-table-head-cell {

    label {
        display: block;
        width: 100%;
        font-size: 21px;
        font-family: "Cooper-Black";
        text-align: right;
    }

    span {
        display: block;
        margin: 30px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }

    p {
        display: unset;
        text-decoration: underline;
    }
}