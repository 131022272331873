
.dispensaries-detail-table {
    height: 100%;
    width: 100%;
    padding: 10px 0px;

    tbody {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}