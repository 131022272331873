
.cart-order-details-table {
    height: 100%;
    width: 100%;

    thead {
        label {
            display: block;
            width: 100%;
            font-size: 17px;
            font-weight: 600;
        }
    }
}