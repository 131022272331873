
.about-page-container {
    min-height: 100vh;
    background-color: #38b23a;
    padding: 50px 12px;

    .about-page-img {

        img {
            min-height: 200px;
            height: auto;
            width: 100%;
        }
    }
}