
.admin-outlet-logo {
    display: block;
    width: 100%;

    label {
        display: block;
        text-align: center;
    }

    .aol-head {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 3px;
    }

    .aol-desc {
        font-size: 16px;
        
    }

    .aol-balance {
        font-size: 16px;
        font-weight: 600;
        margin-top: 8px;

        span {
            font-weight: 500;
        }
    }

    div {
        display: block;
        margin: 20px 0px;
        margin-bottom: 50px;

        button {
            font-size: 16px;
            font-weight: 600;
            padding: 0;
            color: #2a00f8;
            border: none;
            outline: none;
            background: transparent;
            cursor: pointer;
        }
    }
}