
.dispensaries-table-cell {

    td {
        text-align: center;
        cursor: pointer;
    }

    img {
        height: 200px;
        width: auto;
        margin: 0 auto;
    }

    hr {
        width: 86%;
        border: 2px solid #ddd;
    }
}