
.admin-users-table {
    height: 100%;
    width: 100%;
    padding: 30px;

    .admin-users-table-cell {
        width: 100%;
        padding: 10px 0px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;

        div {
            display: flex;
            justify-content: space-between;
            font-size: 16px;

            label {
                font-weight: 600;
            }
        }
    }

    .admin-users-edit-table-cell {
        width: 100%;
        padding: 10px 0px;

        div {
            display: block;
            font-size: 16px;
            margin: 20px 0px;

            label {
                width: 100%;
                font-weight: 600;
            }

            input, select {
                display: block;
                height: 22px;
                width: 100%;
                font-size: 16px;
                color: black;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #aaada7;
                outline: none;
                border-radius: 0;
                margin-top: 10px;
            }

            .user-role-select {
                height: 120px;
                width: 50%;
                background: #d7d7d7;
                padding: 12px;
                border-radius: 4px;

                option:checked {
                    color: white;
                    background-color: #1e90ff;
                }
            }

            button {
                display: block;
                height: 40px;
                width: 100%;
                padding: 0;
                margin: 0 auto;
                border-radius: 6px;
                border: none;
                outline: none;
                background-color: #38b23a;
                color: white;
                font-size: 14px;
                font-weight: 600;
                
                cursor: pointer;
            }

            button:first-child {
                margin-top: 50px;
                margin-bottom: 15px;
            }
        }
    }
}