
.admin-help-page-container {
    min-height: 100vh;
    background-color: #f2f3f5;
    padding-top: 70px;
}

.ahp-fields-container {
    display: block;
    height: auto;
    margin: 0 auto;
    width: 80%;

    label {
        display: block;
        width: 100%;
        font-size: 15px;
        text-align: center;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    input {
        display: block;
        height: 45px;
        width: 100%;
        border: none;
        outline: none;
        border-radius: 7px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 30px;
        background-color: #a6aaa8;
        color: white;
        padding: 0;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    }

    input::placeholder {
        color: white;
    }

    button {
        display: block;
        height: 45px;
        width: 100%;
        outline: none;
        border: unset;
        border-radius: 7px;
        font-size: 16px;
        background-color: #3ab54a;
        color: white;
        margin-top: 18px;
        padding: 2px;
        text-shadow: black 1px 0px 1px;
        cursor: pointer;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
    }
}

.ahp-step-two-fields {

    input {
        margin-bottom: 12px;
    }

    button {
        margin-top: 60px;
    }
}