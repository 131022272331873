
.admin-logo-container {
    display: block;
    width: 100%;
    position: relative;

    img {
        display: block;
        height: auto;
        width: 70%;
        margin: 0 auto;
        object-fit: contain;
    }

    label {
        display: block;
        font-size: 22px;
        font-family: "Cooper-Black";
        text-align: center;
        margin-top: 20px;
    }

    button {
        left: 26px;
        bottom: -2px;
        position: absolute;
        font-size: 26px;
        font-weight: 600;
        padding: 0;
        color: #2a00f8;
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
    }
}