
.admin-outlets-table {
    height: 100%;
    width: 100%;
    padding: 30px;

    .admin-outlets-table-cell {
        width: 100%;
        padding: 10px 0px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;

        div {
            display: flex;
            justify-content: space-between;
            font-size: 16px;

            label {
                font-weight: 600;
            }
        }
    }

    .admin-outlets-edit-table-cell {
        width: 100%;
        padding: 10px 0px;

        div {
            display: block;
            font-size: 16px;
            margin: 20px 0px;

            label {
                width: 100%;
                font-weight: 600;
            }

            input, select {
                display: block;
                height: 22px;
                width: 100%;
                font-size: 16px;
                color: black;
                background-color: transparent;
                border: none;
                border-bottom: 1px solid #aaada7;
                outline: none;
                border-radius: 0;
                margin-top: 10px;
            }

            .users-select {
                height: 120px;
                width: 50%;
                background: #d7d7d7;
                padding: 12px;
                border-radius: 4px;

                option:checked {
                    color: white;
                    background-color: #1e90ff;
                }
            }

            input[type="file"] {
                padding: 10px 0px;
            }

            button {
                display: block;
                height: 40px;
                width: 100%;
                padding: 0;
                margin: 0 auto;
                border-radius: 6px;
                border: none;
                outline: none;
                background-color: #38b23a;
                color: white;
                font-size: 14px;
                font-weight: 600;
                margin-top: 60px;
                cursor: pointer;
            }

            span {
                display: block;
                width: 100%;
                text-align: left;
                margin: 10px 20px;
            }

            .manage-btn {
                height: 26px;
                background-color: transparent;
                color: #2a00f8;
                margin: 0;
                margin-left: 5px;
                margin-right: auto;
                margin-top: 10px;
                font-size: 16px;
                border-bottom: 1px solid #aaada7;
                border-radius: 0;
                text-align: left;
            }
        }

        .locations-container {
            border-bottom: 1px solid #aaada7;

            .manage-btn {
                border-bottom: unset;
            }
        }

        .outlet-locations-container {

            span {
                display: inline-flex;
                justify-content: center;
                width: 100%;
                margin: 10px 0px;

                button {
                    background-color: transparent;
                    color: red;
                    width: 25px;
                    height: 25px;
                    font-size: 20px;
                    margin: 0;
                    position: relative;
                    top: -3px;
                    right: 10px;
                }
            }
        }

        .outlet-add-location-container {
            div {
                margin: 10px 0px;
            }

            .oal-secondary {
                display: inline-flex;
                justify-content: space-between;
                input {
                    width: 30%; 
                }
            }

            .oal-add-location {
                width: auto;
                background-color: transparent;
                color: #2a00f8;
                font-size: 16px;
                margin: 0;
                margin-left: auto;
                margin-right: 0;
            }
        }
        
    }
}