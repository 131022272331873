
.order-status-page-container {
    min-height: 100vh;
    background-color: #eff1f2;
    padding: 0px 16px;
}

.os-header {
    padding: 20px 0px;

    label {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 28px;
        font-family: "Cooper-Black";
    }
}

.os-info {

    label {
        display: block;
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        margin: 10px 0px;

        span {
            font-weight: 200;
        }
    }

    .os-info-sep {
        margin: 35px 0px;
    }
}

.os-card {
    display: block;
    width: 82%;
    max-width: 325px;
    margin: 0 auto;
    
    background-color: #38b23a;
    border-radius: 12px;
    border: 2px solid black;

    .os-card-info-container {
        display: block;
        height: 46px;
        width: 95%;
        padding: 10px 10px 0px 10px;


        .os-card-info {
            float: left;
            width: 45%;

            label {
                display: block;
                font-size: 14px;
            }
        }

        .os-card-visual {
            float: right;

            img {
                height: 40px;
                width: auto;
            }
        }
    }

    .os-card-logo {
        display: flex;
        align-items: center;
        height: 66px;
        background-color: white;

        .awsimg-container {
            height: 66px;
            width: 100px;
            margin: 0 auto;
        }

        img {
            display: block;
            height: 66px;
            width: auto;
            margin: 0 auto;
            object-fit: contain;
        }
    }

    .os-card-amount {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;

        label {

            width: 100%;
            color: white;
            font-size: 26px;
            font-family: "Cooper-Black";
            text-align: center;
        }
    }
}

.os-footer {
    display: block;
    width: 100%;
    margin: 35px 0px;

    a {
        background-color: transparent !important;
        color: black !important;
    }

    .os-footer-b {
        font-weight: 600;
    }

    .os-footer-u {
        text-decoration: underline;
    }

    div {
        display: flex;
        justify-content: center;
        margin: 20px 0px;
    }

    label {
        font-size: 15px;
    }


}