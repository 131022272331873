
.landing-page-container {
    min-height: 100vh;
    background-color: #38b23a;
    padding: 0px 32px;

    div {
        display: flex;
        justify-content: center;
    }

    hr {
        margin-bottom: 12px;
        border: 1px solid #000;
    }

    img {
        display: block;
        height: auto;
        width: 75%;
    }

    label {
        text-align: center;
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;
        font-family: "Copperplate";
    }

    button {
        display: block;
        background-color: white;
        color: black;
        border: 2px solid #000;
        border-radius: 6px;
        padding: 10px 14px;
        font-size: 16px;
        font-weight: 600;
        font-family: "Copperplate";
        margin-bottom: 12px;
        margin-top: 2px;
        cursor: pointer;
        outline: none;
    }

    .a420-logo {
        display: block;
        margin-top: 60px;
        margin-bottom: 50px;
        

        img {
            display: block;
            width: 94%;
            margin: 0 auto;
        }

        label {
            display: block;
            font-size: 30px;
            font-weight: unset;
            font-family: "Stencil";
            margin: 12px 0px;
        }
    }

    .landing-page-text {
        display: block;
        padding: 10px 0px;

        div {
            display: block;

        }

        label {
            display: block;
            width: 100%;
            margin-bottom: 10px;
        }

        button {
            margin: 0 auto;
        }
    }

    .a420-text {
        padding: 30px 0px;
    }
}