
.dispensaries-products-table-cell {
    
    td {
        padding: 12px 0px;
    }

    .dp-info-container {
        display: flex;
        justify-content: space-between;
        padding: 0px 10px;

        label {
            font-size: 16px;
        }

        img {
            height: 50px;
            width: auto;
        }
    }

    .dp-images-container {
        display: block;
        width: auto;
        min-height: 50px;

        .awsimg-container {
            display: inline-block;
            margin-left: 6px;
            cursor: pointer;

            img {
                height: 50px;
                width: 50px;
                object-fit: contain;
                background-color: black;
            }
        }
    }

    .dp-selections-container {
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 35px;

        .dp-selection {

            .dp-selection-control {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 55px;
                border: 1px solid black;
                background-color: white;
                border-radius: 6px;
                position: relative;
                margin-top: 10px;
                margin-right: 10px;
            }

            .dp-selection-quantity {
                text-align: center;
                max-width: 55px;

                label {
                    font-size: 13px;
                    font-weight: 600;
                    position: relative;
                    top: 4px;
                }
            }
            
            label {
                height: 100%;
                width: 100%;
                font-size: 12px;
                text-align: center;
                box-sizing: border-box;
                padding: 10px 0px;
                line-height: 16px;

                span {
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }

        .dp-single-selection {

            .dp-selection-control {
                height: 35px;
                width: 120px;
            }
        }

        .dp-selection-cart-status {
            height: 16px;
            width: 16px;
            border-radius: 50%;
            border: 1px solid black;
            position: absolute;
            right: -5px;
            font-size: 18px;
            text-align: center;
            line-height: 17px;
        }

        .dp-cart-quantity {
            right: unset;
            left: -1px;
            top: -5px;
            background-color: lightblue;
        }

        .dp-cart-add {
            background-color: #38b23a;
            top: -5px;
            cursor: pointer;
        }

        .dp-cart-remove {
            background-color: #ca4b42;
            bottom: -2px;
            line-height: 12px;
            cursor: pointer;
        }
    }

    hr {
        border: 1px solid black;
    }
}